* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
body {
  overflow-x: hidden;
  background: #fff;
  max-width: 565px;
  margin: 0px auto;
}
a {
  text-decoration: none !important;
  word-wrap: break-word;
}
.ar {
  direction: rtl;
}
.ar h3 {
  text-align: right;
}
.ar p {
  text-align: right;
}
.ar .ant-tabs-tab:not(:first-child) {
  margin: 0 32px 0 0px !important;
}
.error {
  color: red !important;
  font-weight: bold !important;

  &.form-field-error {
    margin-top: 5px;
    font-size: 11px;
  }
}
.spinner-main {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
.spinner-border {
  border: 0.25em solid #ff2b85;
  border-right-color: transparent !important;
}
.success {
  color: green !important;
  font-weight: bold !important;
}

button:disabled {
  opacity: 0.6;
}

input:disabled {
  cursor: not-allowed;
  opacity: 0.6 !important;
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1140px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1360px;
  }
}

@media (min-width: 767px) {
  .br-block {
    display: block;
  }
}
