@import "./_variables.scss";
:where(
    .css-dev-only-do-not-override-acm2ia
  )[ant-click-animating-without-extra-node="true"]::after,
:where(.css-dev-only-do-not-override-acm2ia) .ant-click-animating-node {
  opacity: initial;
  inset-inline-start: initial;
  inset-inline-end: initial;
  animation: initial;
}
// top-bar css
.top-bar {
  background: green;
  padding: 2px 0;
  .top-bar-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navbar-brand {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 700;
      font-size: 30px;
      img {
        margin-right: 8px;
      }
    }
    .post-btn {
      .ant-dropdown-trigger {
        background-color: green;
        color: #fff;
        padding: 10px 40px;
        display: inline-block;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        font-size: 16px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .login-btns {
      display: flex;
      align-items: center;
      .ant-space {
        .ant-space-item {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          &:first-child {
            white-space: nowrap;
            overflow: hidden;
            // text-overflow: ellipsis;
          }
          &:nth-child(3) {
            white-space: nowrap;
            width: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .user-avatar {
        margin-right: 10px;
        max-height: 25px;
      }
      &.login-links {
        column-gap: 15px;
        color: white;
        a {
          color: white;
        }
      }
    }
  }
}
// Navbar
.header-nav {
  background: #fafffd;
  box-shadow: 3px 2px 11px rgba(0, 0, 0, 0.04);
  .navbar {
    background-color: transparent !important;
    padding: 0;

    .navbar-nav {
      .show > .nav-link {
        color: green;
      }

      li {
        &:not(:last-child) {
          margin-right: 30px;
        }
        .nav-link.active {
          border-bottom: 2px solid;
          font-weight: 600;
        }
        a {
          color: green;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          border-bottom: 2px solid transparent;
          padding: 15px 8px;
          img {
            margin-right: 7px;
          }
          &:hover,
          &:active {
            color: green;
            border-bottom: 2px solid green;
          }
        }
      }
    }
    .kyc-btn {
      background: green;
      border: 1px solid #cfffee;
      border-radius: 1px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
      display: flex;
      align-items: center;
      height: 33px;
      position: relative;
      &:after {
        content: url("../../assets/images/triangle.png");
        position: absolute;
        top: -2px;
        right: -1px;
      }
      img {
        margin-right: 11px;
      }
    }
  }
}
.navbar-toggler {
  margin-left: auto;
  .fa {
    color: green;
    font-size: 30px;
  }
}
@media (max-width: 1280px) {
  .header-nav {
    .navbar {
      .navbar-nav {
        li {
          &:not(:last-child) {
            margin-right: 22px;
          }
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (max-width: 1140px) {
  .header-nav {
    .navbar {
      .navbar-nav {
        li {
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .header-nav {
    .navbar {
      .navbar-nav {
        li {
          a {
            font-size: 12px;
          }
          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .header-nav .navbar .navbar-nav li a {
    display: inline-block;
  }
  .header-nav .navbar {
    padding-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .top-bar .top-bar-cont .navbar-brand {
    font-size: 16px;
  }
  .top-bar .top-bar-cont .navbar-brand img {
    margin-right: 5px;
  }
  .top-bar .top-bar-cont .login-btns img {
    margin-right: 5px;
  }
}
@media (max-width: 375px) {
  #header-user-info {
    .ant-space {
      gap: 4px !important;
      .ant-space-item {
        font-size: 14px;
        &:first-child {
          width: 75px;
        }
      }
    }
  }
}
