@import "./variables";

.menu {
  position: relative;
  .custom-lang {
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 15px;
  }
  .menu-header {
    position: relative;
    height: 285px;
    .menu-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .menu-nav {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 15px;
      .back {
        background-color: #fff;
        border-radius: 100px;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          path {
            fill: #ff2b85;
          }
        }
      }
      .menu-search {
        display: flex;
        align-items: center;
        button {
          background-color: #fff;
          border-radius: 100px;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: initial;
          &:not(:last-child) {
            margin-right: 10px;
          }
          svg {
            path {
              fill: $Pink;
            }
          }
        }
      }
    }
  }
  .menu-details {
    padding: 15px 15px;
    background-color: #fff;
    position: relative;
    width: 90%;
    margin: -60px auto 2px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 2px #c7c7c7b8;
    .menu-logo {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
      .menu-img {
        max-width: 70px;
        height: 70px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .restraunt-name {
      font-size: 18px;
      font-weight: 600;
    }
    .distance {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      p {
        margin-bottom: 0;
        font-size: 14px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
          path {
            fill: $Pink;
          }
        }
        span {
          font-weight: 600;
        }
      }
      h5 {
        margin-bottom: 0;
        color: $Pink;
        font-size: 14px;
      }
    }
    .stamp-cards {
      background-color: $Beige;
      border-radius: 5px;
      padding: 15px;
      h5 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
  .menu-list {
    padding: 15px 15px 0;
    background-color: #fff;
    position: sticky;
    -webkit-position: sticky;
    top: 0;
    z-index: 1;
    .ant-tabs-nav {
      margin-bottom: 0 !important;
    }
    .ant-tabs-tab {
      font-weight: 500;
      font-size: 18px;
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $Pink !important;
      }
    }
  }
  .popular-sec {
    padding: 15px 15px;

    h3 {
      font-size: 22px;
      font-weight: 700;
      svg {
        margin-right: 10px;
        path {
          fill: #f8ae22;
        }
      }
    }
    .order-text {
      font-size: 14px;
    }
    .pop-box {
      background-color: #f5f5f5;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      border-radius: 5px;
      .pop-item {
        max-width: 48%;
        height: 160px;
        margin-bottom: 10px;
        position: relative;
        border-radius: 10px;
        h4 {
          position: absolute;
          color: #fff;
          font-weight: 600;
          font-size: 20px;
          top: 10px;
          left: 10px;
        }
        .price {
          background-color: #fff;
          position: absolute;
          bottom: -5px;
          right: 10px;
          padding: 2px 10px;
          border-radius: 50px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }
  .fav {
    padding: 15px;
    background-color: #fff;
    h3 {
      font-size: 24px;
      font-weight: 600;
    }
    .category-divider {
      margin-top: 15px;
    }
    .fav-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
      padding: 15px 0;
      h4 {
        font-size: 20px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        margin-bottom: 5;
        max-width: 300px;
      }
      .item-price {
        color: $Pink;
      }
      .popular {
        background-color: #e1e6ec;
        border-radius: 50px;
        padding: 0 8px;
        svg {
          margin-right: 5px;
        }
      }
      .img-box {
        height: 140px;
        width: 150px;
        position: relative;
        border-radius: 5px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
        .add-btn {
          background-color: $Pink;
          border-radius: 100px;
          padding: 5px 5px;
          position: absolute;
          bottom: -8px;
          right: -7px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-ink-bar {
    background-color: $Pink;
  }
  .ant-tabs-nav-wrap:after,
  .ant-tabs-nav-wrap:before {
    box-shadow: initial !important;
  }
  .ant-modal-footer {
    display: none;
  }
  .item-modal {
    .modal-dialog {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      .modal-content {
        border: initial !important;
        .modal-body {
          padding: 0;
          .modal-img {
            height: 250px;
            width: 100%;
            img {
              object-fit: contain;
              height: 100%;
              width: 100%;
            }
          }
          .modal-cont {
            .item-cont {
              display: flex;
              align-items: center;
              justify-content: space-between;
              h3 {
                font-size: 18px;
              }
              p {
                margin: 0;
                color: $Pink;
              }
            }
            padding: 15px 15px;
          }
        }
        .close {
          position: absolute;
          z-index: 1;
          left: 15px;
          top: 10px;
          background-color: #fff;
          border-radius: 100px;
          opacity: 1;
          svg {
            path {
              fill: $Pink;
            }
          }
        }
      }
    }
  }
}
.category-list {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  border-bottom: 1px solid #f5f5f5;

  li {
    width: auto;
    padding: 12px 0;
    &:not(:last-child) {
      margin-right: 32px;
    }
    a {
      font-size: 18px;
      font-weight: 500;
      display: inline-block;
      white-space: nowrap;
      color: #000000e0;
    }
  }
}
.custom-tab {
  font-weight: 600 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  color: #000000e0 !important;
}
.custom-tab.Mui-selected {
  color: #ff2b85 !important;
}
.MuiTabs-indicator {
  background-color: #ff2b85 !important;
  border-radius: 15px !important;
}
